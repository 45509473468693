<template>
  <div>
    <div class="search-bar flex justify-between items-center">
      <a-form-model ref="searchRef" style="flex:1;"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <div class="flex">
          <div class="mr-6">
            <span class="mr-2">城市市场</span>
            <a-select style="width: 200px;" placeholder="请选择城市" 
                v-model="searchForm.outlets_city_adcode" @change="searchData">
              <a-select-option
                v-for="(item, index) in manageCityList"
                :key="index"
                :value="item.adcode"
              >{{ item.name }}</a-select-option>
            </a-select>
          </div>
          <div class="mr-6">
            <span class="mr-2">应交付日期</span>
            <a-range-picker style="width:300px"
              valueFormat="YYYY-MM-DD"
              v-model="date_arr" @change="searchData"
            />
          </div>
          <div>
            <a-button class="mr-4" @click="resetForm">清空日期</a-button>
            <!-- <a-button @click="searchData" type="primary" html-type="submit">搜索</a-button> -->
          </div>
        </div>
      </a-form-model>
    </div>
    
    <base-table ref="tableRef"
                :columnsData="columns"
                :tableData="tableData">
      <template #toolbar>
        <div class="flex items-center mb-2">
          <h4 class="text-lg font-bold mb-0">辖区全部门店的未来待交付产品</h4>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>

import { 
  getGoodsTobeDeliver
} from "@/api/regional/analysis";
import {
  getFinanceCityList,
} from "@/api/outlets"

export default {
  components: {

  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      manageCityList: [],

      date_arr: [],
      searchForm: {
        outlets_city_adcode: "",
        time_start: "",
        time_end: "",
      },
      tableData: [],

      columns: [],
      baseColumns: [
        {
          title: '商品名称',
          dataIndex: 'goods_name',
          align: 'center'
        },
        {
          title: '口味',
          dataIndex: 'flavor_name',
          align: 'center',
        },
        {
          title: '尺寸',
          dataIndex: 'specification_name',
          align: 'center',
        },
        {
          title: '待交付总和',
          dataIndex: 'number',
          align: 'center',
          width: 100,
        },
      ],
    };
  },
  async mounted() {
    this.date_arr = [
      this.getDate(1),
      this.getDate(7),
    ]
    await this.getFinanceCityListData()
    this.searchData();
  },
  methods: {
    // 内部管理的城市列表
    async getFinanceCityListData () {
      const list = this.$store.getters['authCityList']
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
        this.searchForm.outlets_city_adcode = list[0].adcode
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      } 
    },
    async searchData(){
      this.searchForm.time_start = ""
      this.searchForm.time_end = ""

      if(this.date_arr.length == 2){
        if(new Date(this.date_arr[1]).getTime() - new Date(this.date_arr[0]).getTime() > 10 * 24 * 3600 * 1000){
          this.$message.warning("时间跨度不能超过10天")
          return
        }
        this.searchForm.time_start = this.date_arr[0]
        this.searchForm.time_end = this.date_arr[1]
      }

      // this.initData()
      const { data, code } = await getGoodsTobeDeliver(this.searchForm);
      if (code === 0) {
        this.updateDaysData(data.list, !this.searchForm.time_start)
      }
    },

    updateDaysData(list, isSum){
      let columns = this.baseColumns
      let clmns = []
      if(list.length){
        if(!isSum){
          list[0].day_number_list.map((el)=>{
            clmns.push({
              title: el.day.replace(/^\d{4}-/, ""),
              dataIndex: el.day,
              align: 'center',
              width: 80,
            })
          })
          list.map(item=>{
            item.day_number_list.map(d=>{
              item[d.day] = d.number
            })
          })
          columns = this.baseColumns.filter(el=>el.dataIndex!='number')
        }
      }
      this.columns = [].concat(columns, clmns)
      this.tableData = list
    },

    resetForm() {
      this.$refs.searchRef.resetFields();
      this.date_arr = [
        "",
        "",
      ]
      this.searchData()
    },

    getDate(days){
      days = days || 0
      let date = new Date()
          date.setTime(date.getTime() + days*24*3600*1000)
      return this.formatDate(date)
    },

    formatDate(date){
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      month = month+1
      if(month < 10){
        month = "0"+month
      }
      if(day < 10){
        day = "0"+day
      }
      return year+"-"+month+"-"+day
    },
    
  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
